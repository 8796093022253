import React from "react"
import { graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"

const ContactPage = props => {
  const { data, location } = props
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle + ": conference talks"}>
      <Bio />
      <h3>My CFP portfolio</h3>
      <p>
        Here are some examples of photos I made on the CFP basis in the past.
      </p>

      <img src="/cfp-portfolio/cfp-1.jpg" alt="Portrait photo" />
      <img src="/cfp-portfolio/cfp-2.jpg" alt="Portrait photo" />
      <img src="/cfp-portfolio/cfp-3.jpg" alt="Portrait photo" />
      <img src="/cfp-portfolio/cfp-4.jpg" alt="Portrait photo" />
      <img
        src="/cfp-portfolio/cfp-5.jpg"
        alt="Portrait photo"
        style={horizontalImageStyle}
      />
      <img
        src="/cfp-portfolio/cfp-6.jpg"
        alt="Portrait photo"
        style={horizontalImageStyle}
      />
      <img src="/cfp-portfolio/cfp-7.jpg" alt="Portrait photo" />
      <img src="/cfp-portfolio/cfp-8.jpg" alt="Portrait photo" />
      <img src="/cfp-portfolio/cfp-9.jpg" alt="Portrait photo" />
      <img src="/cfp-portfolio/cfp-10.jpg" alt="Portrait photo" />
      <img src="/cfp-portfolio/cfp-11.jpg" alt="Portrait photo" />
      <img src="/cfp-portfolio/cfp-12.jpg" alt="Portrait photo" />
      <img src="/cfp-portfolio/cfp-13.jpg" alt="Portrait photo" />
    </Layout>
  )
}

export default ContactPage

const horizontalImageStyle = {
  position: "relative",
  width: "100vw",
  maxWidth: 1040,
  marginLeft: (653 - 1040) / 2,
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
