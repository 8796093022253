import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = props => {
  const { data, location } = props
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle + ": conference talks"}>
      <SEO
        title="Conference talks"
        keywords={[
          `sharnik`,
          `wojciech ogrodowczyk`,
          `conference talks`,
          `meetups`,
        ]}
      />
      <Bio />
      <h3>Talks that I gave</h3>
      <p>
        Here's a list of talks that I gave at various conferences and meetups
        over the years. I've linked the videos where available.
      </p>
      <p>
        <a href="https://www.youtube.com/watch?v=L8w70uUmOrw">
          <strong>
            Using events and modern state management tools to clean up
            local-first logic
          </strong>
        </a>
        : a talk that presents the idea of event sourcing as a solution to a
        particular problem sometimes encountered when building user-focused
        apps. Delivered at an international conference{" "}
        <strong>React Alicante</strong>.
      </p>
      <p>
        <a href="https://www.youtube.com/watch?v=xf7KBmcUM5A">
          <strong>Beyond JavaScript: The Hidden Benefit of React Native</strong>
        </a>
        : a talk that highlights that we're not limited (doomed?) to be writing
        JavaScript when using React Native. It discusses what other options we
        have (ReasonML and ClojureScript) and why we should take them. Delivered
        at international React conferences <strong>React Day Berlin</strong>,{" "}
        <strong>React Alicante</strong> and later at React Native Berlin and
        Poznań JS meetups.
      </p>
      <p>
        <a href="https://www.youtube.com/watch?v=YeOcxZqOqkw">
          <strong>Hiring for Developers</strong>
        </a>
        : a talk where I discuss how we should approach hiring as developers.
        How to influence the hiring process in your company, what type of
        questions to ask at an interview, how to approach take home assignments
        and finally, why all of it is important. Delivered at a local
        <strong> Software Craftsmanship Barcelona</strong> conference.
      </p>
      <p>
        <a href="https://www.youtube.com/watch?v=lhQadtymBe8">
          <strong>Going Polyglot the Easy Way</strong>
        </a>
        : a talk where I discuss how (and why!) we can (and should!) go beyond
        our go-to programming language even within the confines of our daily
        work. Delivered at an international
        <strong> PolyConf </strong> and local
        <strong> Software Craftsmanship Barcelona</strong> and{" "}
        <strong>Smash Tech Summit Barcelona</strong> conferences.
      </p>
      <p>
        <strong>Out-of-the-box and custom animations in React Native</strong>: a
        talk that goes over the options that React Native developers have for
        implementing animations. From out-of-the-box solutions like
        LayoutAnimation to building your custom interactions. Delivered at a
        React Native Berlin meetup.
      </p>
      <p>
        <strong>Proxying iOS apps for fun & profit</strong>: a live-hacking
        demonstration how you can proxy a popular app on iOS to get access to
        data that you're not supposed to. Delivered at a React Native Barcelona
        meetup.
      </p>
      <p>
        <strong>React Native for cross-platform development</strong>: an
        introduction to development with React Native. Delivered at React Native
        Barcelona and Barcelona JS meetups.
      </p>
      <p>
        <strong>Funkyzeit mit ActiveRecord 3.2</strong>: a pop-quiz that goes
        through examples of commonly used ActiveRecord APIs and asks the
        audience whether particular code sample is vulnerable to an SQL
        injection. The aim was to raise the awareness of the need to validate
        user input. Even though some ActiveRecord methods are protected, many
        are not. Delivered at a Ruby meetup in Poznań.
      </p>
      <p>
        <strong>Primitive Cultures</strong>: a talk where I share some thoughts
        around how the language we use shapes not only our understanding of the
        world, but also might limit what we're capable of learning. Delivered at
        a Clojure meetup in Berlin.
      </p>
      <p>
        <strong>Fat Models, skinny Controllers</strong>: a talk discussing a
        common (but not common enough) practise of moving business logic from
        Controllers into Models and the futher into Services. Delivered at a
        Ruby on Rails meetup in Barcelona.
      </p>
      <p>
        <strong>Why TDD is a Dangerous Sect</strong>: a whimsical talk that
        highlights issues around TDD (the term and the practise). I discussed
        the value of TDD vs test-after approach, popular claims about the TDD
        benefits vs benefits of other code quality practices and overall
        discussed ways we can make our code better. Through TDD or without it.
        Delivered at a Ruby on Rails meetup in Barcelona.
      </p>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
